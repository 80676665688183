<template>
  <div :class="['tyre-car', isOptIn && 'is-optin']">
    <tyre-search
      ref="tyreSearchCar"
      class="tyre-search-car"
      :table-field="tyreCarTableField"
      :table-field-expand="tyreCarTableFieldExpand"
      :table-items="tyreData"
      :select-filter-options="selectFilterOptions"
      :loading="isLoading"
      :is-loading-pagination="isLoadingPagination"
      :is-loading-filter="isLoadingFilter"
      :is-start="isStart"
      :is-no-more-pagination-result="isNoMorePaginationResult"
      :select-filter-fields="selectedSelectFilterFields"
      :attribute-filter-fields="attributeFilterFields"
      :range-filter-fields="rangeFilterFields"
      is-car
      @on-sort="onSortTable"
      @next-page="onNextPage"
      @input-select-filter="onInputSelectFilter"
      @search-filter="onSearchFilter"
      @clear="onClearTable"
    />
  </div>
</template>

<style lang="scss">
@import '@/styles/pages/tyre-car.scss';
</style>

<script>
import TyreSearch from '@/components/TyreSearch';
import {
  tyreCarTableField,
  tyreCarRetailPriceTableField,
  tyreCarTableFieldExpand,
  tyreCarRetailPriceTableFieldExpand,
  selectFilterFields,
  selectFilterFieldsDisabled,
  attributeFilterFields,
  rangeFilterFields,
} from './configs';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    TyreSearch,
  },
  data() {
    return {
      isStart: true,
      isLoading: false,
      isLoadingPagination: false,
      isNoMorePaginationResult: false,
      isLoadingFilter: false,
      selectedFilterPayload: {},
      selectedSelectFilterFields: {},
    };
  },
  beforeRouteEnter(to,from,next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapState('tyre', {
      tyreDataCar: 'tyreDataCar',
      tyreDataSearch: 'tyreDataSearch',
    }),
    ...mapState('pricing', {
      priceType: 'priceType',
      isOptIn: 'isOptIn',
    }),
    ...mapGetters('tyre', ['getTyreCarSelectFilterOptions']),
    tyreData() {
      if (this.$route.query.search) {
        return this.tyreDataSearch;
      }
      return this.tyreDataCar;
    },
    tyreCarTableField() {
      if (!this.isOptIn) {
        return tyreCarTableField;
      } else {
        return tyreCarRetailPriceTableField;
      }
    },
    tyreCarTableFieldExpand() {
      if (!this.isOptIn) {
        return tyreCarTableFieldExpand;
      } else {
        return tyreCarRetailPriceTableFieldExpand;
      }
    },
    selectFilterOptions() {
      return this.getTyreCarSelectFilterOptions(selectFilterFields);
    },
    attributeFilterFields() {
      return attributeFilterFields;
    },
    rangeFilterFields() {
      return rangeFilterFields;
    },
  },
  watch: {
    $route(val) {
      if (!val.query.search) {
        this.$refs.tyreSearchCar.onResetFilter();
      } else {
        this.selectedSelectFilterFields = selectFilterFieldsDisabled;
        this.handleTyreSizeFilter();
      }
    },
  },
  async created() {
    // if it's not search
    if (!this.$route.query.search) {
      this.selectedSelectFilterFields = selectFilterFields;
      this.isLoadingFilter = true;
      this.$store.dispatch('tyre/getTyresConcatenated', {
        vehicleTypes: 'car',
        commitVehicleType: 'car',
      }).finally(() => {
        this.isLoadingFilter = false;
      });
      return;
    }

    // if it's search
    this.selectedSelectFilterFields = selectFilterFieldsDisabled;
    const seasonFilterValue = this.$route.query.search.charAt(0).toUpperCase();
    if (['S','W','A','G'].includes(seasonFilterValue)) {
      let adjustedSeasonFilterValue = seasonFilterValue;
      if (seasonFilterValue === 'G') {
        adjustedSeasonFilterValue = 'A';
      }
      this.selectedFilterPayload = {
        ...this.selectedFilterPayload,
        seasons: adjustedSeasonFilterValue,
      };
    }

    // if already had search data from front page
    if (this.tyreDataSearch.length > 0) {
      this.isStart = false;
      return;
    }

    this.isLoading = true;
    // if it's search and page is refreshed
    try {
      await this.$store.dispatch('tyre/searchTyreResult', {
        searchValue: this.$route.query.search,
        filters: {
          ...this.selectedFilterPayload,
          vehicleTypes: this.selectedFilterPayload.vehicleTypes ?
            this.selectedFilterPayload.vehicleTypes : 'car,lightTruck,offRoad',
          isVehicleType: true,
        },
      });
      this.handleTyreSizeFilter();
    } catch (err) {
      const data = err.response.data;
      const status = err.response.status;
      this.$store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title: 'Error tyres',
        message: `<p>${data['error_title']} - ${status}</p>`,
      });
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
      this.isStart = false;
    }
  },
  mounted() {
    // if already had search data from front page
    if (this.tyreDataSearch.length > 0) {
      this.handleTyreSizeFilter();
    }
  },
  methods: {
    getFilterPayload(filters) {
      let payload = {};
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          payload = {
            ...payload,
            [key]: filters[key].join(),
          };
        } else if (!Array.isArray(filters[key]) && (filters[key] || filters[key] === 0)) {
          payload = {
            ...payload,
            [key]: filters[key],
          };
        }
      });
      return payload;
    },
    async onSearchFilterIsSearch(filters) {
      const payload = this.getFilterPayload(filters);
      this.selectedFilterPayload = {
        ...payload,
        sortField: 'bestPrice',
        sortDirection: 'ASC',
      };
      this.isLoading = true;
      this.isNoMorePaginationResult = false;

      let searchPayload = this.$route.query.search;
      const seasonFilterValue = searchPayload.charAt(0).toLowerCase();
      if (['s','w','a','g'].includes(seasonFilterValue)) {
        let adjustedSeasonFilterValue = seasonFilterValue;
        if (seasonFilterValue === 'g') {
          adjustedSeasonFilterValue = 'a';
        }
        const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
        if (filteredSeasons.length !== 1) {
          searchPayload = searchPayload.slice(1);
        } else if (
          filteredSeasons.length === 1 &&
            adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
        ) {
          searchPayload = searchPayload.slice(1);
        }
      }

      const splitSearchValues = searchPayload.split(' ');
      if (splitSearchValues.length > 1) {
        const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
        if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
          searchPayload = splitSearchValues[0];
        } else if (
          filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
        ) {
          searchPayload = splitSearchValues[0];
        }
      }

      try {
        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: searchPayload,
          filters: {
            ...this.selectedFilterPayload,
            isSearch: true,
            vehicleTypes: this.selectedFilterPayload.vehicleTypes ?
              this.selectedFilterPayload.vehicleTypes : 'car,lightTruck,offRoad',
            isVehicleType: true,
          },
        });
        this.isStart = false;
        if (filters.isVehicleType && this.tyreData.length === 0) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.no-tyre-found-header'),
            message: this.$t('langkey.please-select-another-vehicle-type'),
          });
        }
      } catch (err) {
        if (err === 'noResult') {
          return;
        }
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    async onSearchFilter(filters) {
      if (this.$route.query.search) {
        this.onSearchFilterIsSearch(filters);
        return;
      }

      const payload = this.getFilterPayload(filters);
      this.selectedFilterPayload = { // initial tyre table sort
        ...payload,
        sortField: 'bestPrice',
        sortDirection: 'ASC',
      };
      this.isLoading = true;
      this.isNoMorePaginationResult = false;

      try {
        await this.$store.dispatch('tyre/getTyres', {
          vehicleTypes: 'car',
          ...this.selectedFilterPayload,
        });
        this.isStart = false;
        if (filters.isVehicleType && this.tyreData.length === 0) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.no-tyre-found-header'),
            message: this.$t('langkey.please-select-another-vehicle-type'),
          });
        }
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      }
    },
    onInputSelectFilter(filters) {
      let vehicleTypeSearch = 'car,lightTruck,offRoad';
      if (filters.initialSearch) {
        this.isStart = false;
        delete filters.initialSearch;
      }
      // if search data from front page
      if (this.prevRoute.name) {
        vehicleTypeSearch = '';
      }
      const payload = this.getFilterPayload(filters);
      this.isLoadingFilter = true;
      this.$store.dispatch('tyre/getTyresConcatenated', {
        vehicleTypes: this.$route.query.search ? vehicleTypeSearch : 'car',
        query: this.$route.query.search || '',
        commitVehicleType: 'car',
        ...payload,
      }).finally(() => {
        this.isLoadingFilter = false;
      });
    },
    async onSortTableIsSearch({ activeSortCol, status }) {
      this.isLoading = true;
      this.isNoMorePaginationResult = false;
      if (status === 'none') {
        delete this.selectedFilterPayload.sortField;
        delete this.selectedFilterPayload.sortDirection;
      } else {
        this.selectedFilterPayload = {
          ...this.selectedFilterPayload,
          sortField: activeSortCol,
          sortDirection: status,
        };
      }

      try {
        let searchPayload = this.$route.query.search;
        const seasonFilterValue = searchPayload.charAt(0).toLowerCase();

        if (['s','w','a','g'].includes(seasonFilterValue)) {
          let adjustedSeasonFilterValue = seasonFilterValue;
          if (seasonFilterValue === 'g') {
            adjustedSeasonFilterValue = 'a';
          }
          const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
          if (filteredSeasons.length !== 1) {
            searchPayload = searchPayload.slice(1);
          } else if (filteredSeasons.length === 1
              && adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
          ) {
            searchPayload = searchPayload.slice(1);
          }
        }

        const splitSearchValues = searchPayload.split(' ');
        if (splitSearchValues.length > 1) {
          const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
          if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
            searchPayload = splitSearchValues[0];
          } else if (
            filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
          ) {
            searchPayload = splitSearchValues[0];
          }
        }

        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: searchPayload,
          filters: {
            ...this.selectedFilterPayload,
            isSearch: true,
            vehicleTypes: this.selectedFilterPayload.vehicleTypes ?
              this.selectedFilterPayload.vehicleTypes : 'car,lightTruck,offRoad',
            isVehicleType: true,
          },
        });
        this.isStart = false;
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async onSortTable({ activeSortCol, status }) {
      if (this.$route.query.search) {
        this.onSortTableIsSearch({ activeSortCol, status });
        return;
      }

      this.isLoading = true;
      this.isNoMorePaginationResult = false;
      if (status === 'none') {
        delete this.selectedFilterPayload.sortField;
        delete this.selectedFilterPayload.sortDirection;
      } else {
        this.selectedFilterPayload = {
          ...this.selectedFilterPayload,
          sortField: activeSortCol,
          sortDirection: status,
        };
      }

      try {
        await this.$store.dispatch('tyre/getTyres', {
          vehicleTypes: 'car',
          ...this.selectedFilterPayload,
        });
        this.isStart = false;
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      }
    },
    async onNextPageIsSearch(page) {
      this.isLoadingPagination = true;
      let searchPayload = this.$route.query.search;
      const seasonFilterValue = searchPayload.charAt(0).toLowerCase();
      if (['s','w','a','g'].includes(seasonFilterValue)) {
        let adjustedSeasonFilterValue = seasonFilterValue;
        if (seasonFilterValue === 'g') {
          adjustedSeasonFilterValue = 'a';
        }
        const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
        if (filteredSeasons.length !== 1) {
          searchPayload = searchPayload.slice(1);
        } else if (filteredSeasons.length === 1
            && adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
        ) {
          searchPayload = searchPayload.slice(1);
        }
      }

      const splitSearchValues = searchPayload.split(' ');
      if (splitSearchValues.length > 1) {
        const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
        if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
          searchPayload = splitSearchValues[0];
        } else if (
          filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
        ) {
          searchPayload = splitSearchValues[0];
        }
      }

      try {
        await this.$store.dispatch('tyre/searchTyreResultNextPage', {
          searchValue: searchPayload,
          filters: {
            ...this.selectedFilterPayload,
            page: page,
            isSearch: true,
            vehicleTypes: 'car,lightTruck,offRoad',
            isVehicleType: true,
          },
        });
      } catch (err) {
        if (err === 'noResult') {
          this.isNoMorePaginationResult = true;
          this.isLoadingPagination = false;
          return;
        }

        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoadingPagination = false;
      }
    },
    async onNextPage(page) {
      if (this.$route.query.search) {
        this.onNextPageIsSearch(page);
        return;
      }
      this.isLoadingPagination = true;
      try {
        await this.$store.dispatch('tyre/getTyresNextPage', {
          vehicleTypes: 'car',
          ...this.selectedFilterPayload,
          page: page,
        });
      } catch (err) {
        if (err === 'noResult') {
          this.isNoMorePaginationResult = true;
          this.isLoadingPagination = false;
          return;
        }
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoadingPagination = false;
      }
    },
    onClearTable() {
      this.selectedSelectFilterFields = selectFilterFields;
      this.isStart = true;
      this.isLoadingFilter = true;
      const vehicleType = 'car';
      if (this.$route.query.search) {
        this.$refs.tyreSearchCar.onResetInitFilterVehicleType();
        this.$router.replace({
          name: this.$route.name,
          query: null,
        });
      }

      this.$store.dispatch('tyre/getTyresConcatenated', {
        vehicleTypes: vehicleType,
        commitVehicleType: 'car',
      }).finally(() => {
        this.isLoadingFilter = false;
      });
      this.$store.dispatch('tyre/clearTyresTable',{
        vehicleTypes: vehicleType,
        commitVehicleType: 'car',
      });
    },
    handleTyreSizeFilter() {
      // Set tyre size filter if matchcode is old format
      const tyreDataWidths = [...new Set(this.tyreData.map((item) => item.width))];
      const tyreDataAspectRatios = [...new Set(this.tyreData.map((item) => item.aspectRatio))];
      const tyreDataDiameters = [...new Set(this.tyreData.map((item) => item.diameter))];
      this.$refs.tyreSearchCar.setTyreSizeFilter({
        widths: tyreDataWidths,
        aspectRatios: tyreDataAspectRatios[0] !== '' ? tyreDataAspectRatios : [' '],
        diameters: tyreDataDiameters,
      });
    },
  },
};
</script>